.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.active-link {
  color: hsl(340, 70%, 52%);
}
.pagination {
  padding: 5px;
  /* margin: 15px 0; */
  display: flex;
  justify-content: center;
}

.pagination > span{
  padding: 5px 10px;
  border: 1px solid rgb(243, 239, 239);
  border-radius: 5px;
  cursor: pointer;
}

.pagination__disable{
  display: none;
}

.pagination__selected{
  background-color: #81D742;
  color: white;
}
iframe#webpack-dev-server-client-overlay{display:none!important}
.carousel-shadow {
  box-shadow: 0px 0px 16px 0px gray;
}
